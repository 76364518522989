import React from "react"
import { RiMailLine, RiPhoneLine, RiWhatsappLine } from "react-icons/ri"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/seo"
import Layout from "../components/layout"

const Kontakt = () => (
  <Layout className="page">
    <SEO title="BELMOT Oldtimerversicherung – Kontakt" />
    <div className="grid-container grid-container--start">
      <h1>Kontakt</h1>

      <div className="about-text">
        <div className="grids col-1 lg-2">
          <h2>
            Über uns - <span>Kompetenz, Erfahrungen und Expertise</span>
          </h2>
        </div>
        <div className="grids col-1 sm-2">
          <p>
            Bereits seit 2000 bin ich in der Versicherungsbranche tätig und habe
            mich nachhaltig mit den Spezialisierungen der Mannheimer
            Versicherung AG etabliert: Sie finden mein Büro direkt im Oldtimer
            Autohaus Isernhagen. Dieser Bezug ist nicht nur der thematischen
            Nähe geschuldet, sondern erleichtert die Prozesse und die
            Zusammenarbeit mit Classic Data, FSP, TÜV oder GTÜ. Darüber hinaus
            präsentiert sich die Mannheimer Versicherung AG bei Fachmessen, wie
            der Essen Motor Show, der Techno Classica oder Retro Classics - ich
            freue mich auf Ihren Besuch.
          </p>
          <p>
            Sie sind schon Belmot Kunde oder wollen es werden? Gerne treffe ich
            mich mit Ihnen auch auf der Messe auf einen leckeren Kaffee.
            Kostenlose Eintrittskarten erhalten sie selbstverständlich auch von
            mir. Natürlich nur solange mein Vorrat reicht.
          </p>
        </div>
      </div>
      <div className="about-contact grids col-1 lg-2">
        <div className="about-contact--item">
          <StaticImage
            src="../../static/assets/Wolff 188x188.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="about-contact--image"
          />
          <div>
            <h4>Philipp Wolff</h4>
            <p>
              <a href="mailto:philipp.wolff1@mannheimer.de">
                <RiMailLine />
                philipp.wolff1@mannheimer.de
              </a>
              <a href="tel:0157 72542995">
                <RiPhoneLine />
                0157 72542995
              </a>
            </p>
          </div>
        </div>
        <div className="about-contact--item">
          <StaticImage
            src="../../static/assets/Ikierski 188x188.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="about-contact--image"
          />
          <div>
            <h4>Guido Ikierski</h4>
            <p>
              <a href="mailto:guido.ikierski@gmx.de">
                <RiMailLine />
                guido.ikierski@gmx.de
              </a>
              <a href="tel:05139 983330">
                <RiPhoneLine />
                05139 983330
              </a>
              <a href="https://wa.link/mdjfc6">
                <RiWhatsappLine />
                0171 6201108
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="grids col-1 lg-2">
        <h2>Unsere Partner</h2>
      </div>
      <div className="partner grids col-2 md-3 lg-4">
        <a
          className="partner__item"
          href="http://www.classicmotorshow.de/home/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/bremen_classic.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://www.csa.autopartner-portal.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/auto_plus.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://sportwagentechnik-ekkert.de/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/ekkert.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://www.essen-motorshow.de/automobilmesse/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/essen_motorshow.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://berater.finanzen.de/profil/guido.ikierski"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/finanzen.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="https://www.glinicke.de/classic-cars/kassel/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/glinicke.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <div className="partner__item">
          <StaticImage
            src="../../static/assets/partner/kundenportal.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </div>
        <a
          className="partner__item"
          href="https://www.svs-gutachten.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/svs.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://www.fsp-classic.de/wp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/tuev_rheinland.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item"
          href="http://www.vhclassics.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StaticImage
            src="../../static/assets/partner/vhclassics.jpg"
            alt="partner"
            layout="fullWidth"
            quality="100"
            className="partner__image"
            objectFit="contain"
          />
        </a>
        <a
          className="partner__item partner__item--copy"
          href="https://www.porsche.com/germany/accessoriesandservices/classic/porsche-classic-card/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Zur Porsche Classic Card
        </a>
      </div>
    </div>
  </Layout>
)

export default Kontakt
